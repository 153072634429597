import React, { Component } from 'react'
import { Location } from '@reach/router'
import { Link } from 'gatsby'
import { Menu, X } from 'react-feather'
import LogoCVnav from './LogoCVnav'
import './Nav.css'
// import Popup from '../components/Popup'

export class Navigation extends Component {
  state = {
    active: false,
    activeSubNav: false,
    currentPath: false
  }

  componentDidMount = () =>
    this.setState({ currentPath: this.props.location.pathname })

  handleMenuToggle = () => this.setState({ active: !this.state.active })

  // Only close nav if it is open
  handleLinkClick = () => this.state.active && this.handleMenuToggle()

  toggleSubNav = subNav =>
    this.setState({
      activeSubNav: this.state.activeSubNav === subNav ? false : subNav
    })

  toggleSubNav2 = subNav2 =>
    this.setState({
      activeSubNav2: this.state.activeSubNav2 === subNav2 ? false : subNav2
    })

  toggleSubNav3 = subNav3 =>
    this.setState({
      activeSubNav3: this.state.activeSubNav3 === subNav3 ? false : subNav3
    })

  render() {
    const { active } = this.state,
      { subNav } = this.props,
      NavLink = ({ to, className, children, ...props }) => (
        <Link
          to={to}
          className={`NavLink ${
            to === this.state.currentPath ? 'active' : ''
          } ${className}`}
          onClick={this.handleLinkClick}
          {...props}
        >
          {children}
        </Link>
      )

    return (
      <nav className={`Nav ${active ? 'Nav-active' : ''}`}>
        <div className="Nav--Container container">
          <Link to="/" onClick={this.handleLinkClick}>
            <LogoCVnav />
          </Link>
          <div className="Nav--Links">
            <NavLink to="/">Home</NavLink>
            {/* <NavLink to="/about">Meet Candace</NavLink> */}
            <NavLink to="/video/">My Story</NavLink>
            <NavLink to="/endorse/">2020 Endorsers</NavLink>
            {/* <NavLink to="/issues/">Issues</NavLink> */}
            <NavLink to="/media/">Media</NavLink>
            {/* <div
              className={`Nav--Group ${
                this.state.activeSubNav2 === 'posts' ? 'active' : ''
              }`}
            >
              <span
                className={`NavLink Nav--GroupParent ${
                  this.props.location.pathname.includes('posts') ||
                  this.props.location.pathname.includes('blog') ||
                  this.props.location.pathname.includes('post-categories')
                    ? 'active'
                    : ''
                }`}
                onClick={() => this.toggleSubNav2('posts')}
              >
                Media
                <div className="Nav--GroupLinks">
                  <NavLink to="/media/">Media</NavLink>
                  <NavLink to="/statements/">Campaign Statements</NavLink>
                </div>
              </span>
            </div>      
            <div
              className={`Nav--Group ${
                this.state.activeSubNav === 'posts' ? 'active' : ''
              }`}
            >
              <span
                className={`NavLink Nav--GroupParent ${
                  this.props.location.pathname.includes('posts') ||
                  this.props.location.pathname.includes('blog') ||
                  this.props.location.pathname.includes('post-categories')
                    ? 'active'
                    : ''
                }`}
                onClick={() => this.toggleSubNav('posts')}
              >
                Volunteer


                <div className="Nav--GroupLinks">
                  <NavLink to="/pollgreeter/">Poll Greeting</NavLink>
                  <NavLink to="/volunteer/">Volunteer</NavLink>
                  <NavLink to="/intern/">Internship</NavLink>
                </div>
              </span>
            </div>      
            <NavLink to="/events/">Events</NavLink> 
            <div
              className={`Nav--Group ${
                this.state.activeSubNav3 === 'posts' ? 'active' : ''
              }`}
            >
              <span
                className={`NavLink Nav--GroupParent ${
                  this.props.location.pathname.includes('posts') ||
                  this.props.location.pathname.includes('blog') ||
                  this.props.location.pathname.includes('post-categories')
                    ? 'active'
                    : ''
                }`}
                onClick={() => this.toggleSubNav3('posts')}
              >
                Shop
                <div className="Nav--GroupLinks">
                  <NavLink to="/yardsigns/">Yard Signs</NavLink>
                  <a href="https://store.candacefor24.com" className="Outlink"><div >Store</div></a>
                </div>
              </span>
            </div>         
            <a href="https://secure.actblue.com/donate/cd24?refcode=website"><button className="Button donate-button">  Donate  </button></a>
            <NavLink to="/default/">About 24</NavLink>*/}
          </div>
          <button
            className="Button-blank Nav--MenuButton"
            onClick={this.handleMenuToggle}
          >
            {active ? <X /> : <Menu />}
          </button> 
        </div>
      </nav>
    )
  }
}

export default ({ subNav }) => (
  <Location>{route => <Navigation subNav={subNav} {...route} />}</Location>
)
