import React from 'react'
import InstagramFeed from './InstagramFeed'
import SocialFollow from "./SocialFollow";
import Logo from './LogoCV'
import './Footer.css'

export default () => (
  <div>
    <div className="logo"><Logo /></div>
    <h2 className="taCenter">
      Follow us{' '}
      <SocialFollow />
    </h2>
    <br />
    <InstagramFeed count="8" />
    <footer className="footer">
    <div className="container taCenter">General Inquiries: <a href={"mailto: Info@Candacefor24.com"}>Info@Candacefor24.com</a></div>
    <div className="container taCenter">Media Inquiries: <a href={"mailto: info@candacefor24.com"}>info@candacefor24.com</a></div>
    <div className="container taCenter">Phone: (214) 865-9826</div>
      <div className="container taCenter">
        <span className="paid">
          Paid for by Candace for 24
        </span>
      </div>
    </footer>
  </div>
)
